<template>
  <div class="Profile">
    <div class="opt-bar flex">
      <el-button @click="showPwdForm=true" size="small" v-ripple>修改密码</el-button>
    </div>

    <el-alert v-if="errorMsg"
      :title="errorMsg"
      type="error"
      effect="dark">
    </el-alert>
    <el-alert v-if="successMsg"
      :title="successMsg"
      type="success"
      effect="dark">
    </el-alert>
    <div class="opt-card">
      <el-card class="myinfo" v-if="login" shadow="always">
        <div class="card-header">
          <div class="card-header-text">基本信息</div>
        </div>
        <el-divider class="divider"></el-divider>
        <div class="row" style="margin-top: 20px;">
          <div class="label">姓名：</div>
          <div class="value" v-html="login.name"></div>

        </div>
        <div class="row">
          <div class="label">手机：</div>
          <div class="value" v-html="login.username"></div>
        </div>
        <div class="row">
          <div class="label">应用：</div>
          <div class="value" v-html="login.platid"></div>
        </div>
      </el-card>

      <el-card class="myinfo" v-if="login && showPwdForm" shadow="always">
        <div class="card-header">
          <div class="card-header-text">修改密码</div>
          <div class="card-header-icon" @click="showPwdForm=false" v-ripple><i class="iconfont">&#xe623;</i></div>
        </div>
        <el-divider class="divider"></el-divider>
        <div class="pwd-form">
          <el-form
            class="form"
            :rules="formRules"
            ref="pwdForm"
            label-position="right"
            label-width="80px"
            size="medium"
            :model="formPwd"
          >
            <el-form-item class="input-label" label="当前密码" prop="password">
              <el-input
                v-model="formPwd.password"
                type="password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item class="input-label" label="新密码" prop="password">
              <el-input
                v-model="formPwd.newpwd"
                type="password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item class="input-label" label="确认新密码" prop="password">
              <el-input
                v-model="formPwd.newpwd2"
                type="password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="input-label input-lable-code"
              label="验证码"
              prop="imgcode"
            >
            <el-input
                class="input input-code"
                v-model="formPwd.imgcode"
                @keyup.enter.native="onPwdSubmitClick('pwdForm')"
              ></el-input>
              <img
                class="imgcode"
                @click="onImageCodeClick"
                :src="api + '/home/code?t=' + random"
              />
            </el-form-item>
          </el-form>
          <div class="btn-line">
            <el-button
              class="btn"
              type="primary"
              @click="onPwdSubmitClick('pwdForm')"
              >修改密码</el-button
            >
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',

  data() {
    return {
      login: false,
      api: "",
      random: 0.123,
      showPwdForm: true,
      errorMsg: '',
      successMsg: '',
      formPwd: {
        password: "123123",
        newpwd: '123123',
        newpwd2: '123123',
        imgcode: "",
      },
      formRules: {
        password: [
          {
            required: true,
            message: "请输入正确的密码",
            min: 6,
            max: 16,
            trigger: "blur",
          },
        ],
        imgcode: [
          {
            required: true,
            message: "请输入正确的验证码",
            min: 4,
            max: 4,
            trigger: "blur",
          },
        ],
      },
    }
  },
  created (opt) {
    this.login = this.getItem('login')
    this.random = this.util.uuid();
    this.api = this.global.api;
  },
  mounted(){
    // this.loading.show();
  },
  methods: {
    onImageCodeClick() {
      this.random = this.util.uuid();
    },
    onPwdSubmitClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formPwd.hash = this.random
          this.post('api/me/changePwd', this.formPwd, res=>{
            if (res.code != 0) {
              this.errorMsg ='修改密码失败~' + res.msg
              return
            }
            this.toast('修改密码成功！')
          })
        } else {
          this.toast('数据不对，请检查~')
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.Profile {
  margin-top: 0px;

  .myinfo{
    width: 32em;
    text-align: left;
    margin: 10px;

    .divider {
      margin-top: 5px;
      margin-bottom: 5px;
    }

  }
}

.opt-bar {
  display: flex;
  margin-bottom: 3px;
  margin-top: -5px;
  background-color: #ebebeb;
  padding: 5px;
}
.row {
 display: flex;
 font-size: 1.3em;
 height: 36px;

  .label {
    min-width: 5em;
    text-align: right;
  }
  .value {
    margin-left: 10px;
    color: $primary;
  }
}

.opt-card {
  display: flex;
  flex-wrap: wrap;

  .card-header-text {
    font-size: 1.2em;
    font-weight: 700;
  }
  .card-header-icon {
    float: right;
    margin-top: -30px;
    font-size: 18px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
  .card-header-icon:hover{
    background-color: $primary;
    color: #fff;
    border-radius: 10px;
  }
}

.pwd-form {
  margin-top: 10px;

  .form{
    display: flex;
    flex-direction: column;
  }
  .input-label {
    margin-top: 0.3em;
  }

  .input {
    width: 10em;
  }

  .btn-line {
    text-align: center;
  }

  .input-code {
    width: 9em;
    float: left;
  }

  .imgcode {
    float: left;
    height: 2.5em;
    margin-left: 1em;
  }
}
</style>
